import { performAuthorizedRequest } from '../apiConfig';

const reviewsProviderPath = '/client_actions';
const reviewsVisitorPath = '/verified_visitor_actions';

export const createReviewVisitor = (query) => performAuthorizedRequest(`${reviewsVisitorPath}/create_review`, query);

export const destroyReviewVisitor = (query) => performAuthorizedRequest(`${reviewsVisitorPath}/destroy_review`, query);

export const answerForReviewProvider = (query) => performAuthorizedRequest(`${reviewsProviderPath}/answer_for_review`, query);
