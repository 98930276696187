/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { createReviewVisitor } from 'api/requests/reviews';

import { useLocalContext } from 'LocalContext';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { paths } from 'constants';
import { reviewsStatus } from 'constants/reviewsStatus';

import { Button } from 'components/Button';
import { ReviewItem } from './components/ReviewItem';
import { RigthIcon } from 'components/ActiveIcon/RigthIcon';
import { LeftIcon } from 'components/ActiveIcon/LeftIcon';

import reviewStars from 'assets/reviewStars.svg';

import styles from './styled.module.css';
import './styles.css';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <RigthIcon className="arrow" />
    </div>
  );
};

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <LeftIcon className="arrow" />
    </div>
  );
};

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

const settings = {
  className: 'center',
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Reviews = ({ reviews = [], profile }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [showTextArea, setShowTextArea] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [reviewsData, setReviewsData] = useState(reviews);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    setReviewsData(
      userInfo?.user_type === userVisitor
        && location.pathname !== paths.myProfile
        ? [
          ...reviews.sort((a, b) => {
            if (
              a.review.id_name === userInfo?.profile?.nickname
                && b.review.id_name !== userInfo?.profile?.nickname
            ) {
              return -1;
            }
            if (
              a.review.id_name !== userInfo?.profile?.nickname
                && b.review.id_name === userInfo?.profile?.nickname
            ) {
              return 1;
            }
            return 0;
          }),
        ]
        : [...reviews.reverse()],
    );
  }, [reviews.length]);

  useEffect(() => {
    if (showTextArea) {
      const button = document.getElementById('formButton');
      button.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showTextArea]);

  const sendReview = async () => {
    setLoading(true);
    const { text } = form.getFieldsValue();
    const query = {
      profile_id: profile.id,
      text,
    };

    try {
      const {
        data: { reviews: reviewsRes },
      } = await createReviewVisitor(query);

      const userReviewIds = new Set(userInfo?.reviews?.map((el) => el.id));
      const review = reviewsRes.find((item) => !userReviewIds.has(item.id));

      setError(false);
      setIsSend(true);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          reviews: [
            ...(userInfo?.reviews ? userInfo.reviews : []),
            {
              id: review.id,
              review: {
                text: review.text,
                id_name: userInfo?.profile?.nickname,
                status: review.text_status,
                name: profile.nickname,
                link: profile.id_name,
                card_id: profile.id,
              },
            },
          ],
        },
      });
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const isHaveVisitorReview = userInfo?.user_type === userVisitor
    && userInfo?.reviews?.some((item) => item?.review?.card_id === profile?.id);

  const isShowTitle = !(
    location.pathname === paths.myProfile && userInfo?.user_type === userVisitor
  );

  const isShowForm = userInfo?.user_type !== userProvider
    && !isHaveVisitorReview
    && !isSend
    && !error
    && isShowTitle;

  const isReviewOnModeration = userInfo
    ?.reviews?.find((item) => item?.review?.card_id === profile?.id)
    ?.review?.status === reviewsStatus.moderation;

  const isReviewDeclined = userInfo?.reviews?.find((item) => item?.review?.card_id === profile?.id)
    ?.review?.status === reviewsStatus.declined;

  let titleText = <h3 className={styles.title}>{t('components.reviews.1')}</h3>;
  if (isHaveVisitorReview && !isSend) {
    titleText = (
      <h3 className={`${styles.title} ${isReviewDeclined ? styles.declined : ''}`}>
        {isReviewOnModeration
          ? t('components.reviews.18')
          : isReviewDeclined
            ? t('components.reviews.24')
            : t('components.reviews.2')}
      </h3>
    );
  } else if (isSend) {
    titleText = (
      <div className={styles.title}>
        <h3>{t('components.reviews.3')}</h3>
        <p>{t('components.reviews.4')}</p>
      </div>
    );
  } else if (error) {
    titleText = (
      <div className={styles.title}>
        <h3>{t('components.reviews.2')}</h3>
        <p className={styles.error}>{t('components.reviews.22')}</p>
      </div>
    );
  }

  const onClickLeaveButton = async () => {
    if (userInfo?.user_type === userVisitor) {
      setShowTextArea(!showTextArea);
      if (showTextArea) {
        await sendReview();
      }
    } else {
      navigate(paths.registration, {
        state: { userType: userVisitor },
      });
    }
  };

  return (
    <section className={styles.wrapper}>
      {isShowTitle && (
        <div className={styles.stars}>
          <img src={reviewStars} alt="reviewStars" />
          {/* <p className={styles.starsCount}>5.0</p> */}
          {/* <p className={styles.reviewsCount}> */}
          <p className={styles.starsCount}>
            {reviewsData.length}
            {' '}
            {t('components.reviews.5')}
          </p>
          {userInfo?.user_type !== userProvider && titleText}
        </div>
      )}
      {isShowForm && (
        <Form autoComplete="off" form={form} className={styles.form}>
          {showTextArea && (
            <Form.Item
              name="text"
              className="report"
              rules={[
                {
                  required: true,
                  message: t('errors.enterMessage'),
                },
              ]}
            >
              <TextArea
                placeholder={t('components.reviews.6')}
                maxLength={400}
                showCount
                allowClear
              />
            </Form.Item>
          )}
          <Form.Item dependencies={['text']}>
            {() => {
              const dependentValue = form.getFieldValue('text');
              return (
                <Button
                  onClick={onClickLeaveButton}
                  id="formButton"
                  secondary
                  submit
                  loading={loading}
                  disabled={showTextArea && !dependentValue}
                >
                  {t('components.reviews.7')}
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      )}
      {!!reviewsData.length && (
        <Slider
          {...settings}
          className={`${styles.slider} reviewsCarousel`}
          beforeChange={() => setScroll(true)}
          afterChange={() => setScroll(false)}
        >
          {reviewsData.map((item) => (
            <ReviewItem
              key={item.id}
              item={item}
              setReviewsData={setReviewsData}
              scroll={scroll}
            />
          ))}
        </Slider>
      )}
    </section>
  );
};

Reviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      review: PropTypes.shape({
        text: PropTypes.string,
        id_name: PropTypes.string,
      }),
      answer: PropTypes.shape({
        text: PropTypes.string,
        id_name: PropTypes.string,
      }),
    }),
  ),
  profile: PropTypes.shape({
    id: PropTypes.number,
    nickname: PropTypes.string,
    id_name: PropTypes.string,
  }),
};

export { Reviews };
