import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reactAppPublicApiUrlSecretPassword } from '../../config';
import { savedConstants } from 'constants';

import styles from './styled.module.css';

const EntryPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const onFinish = async (values) => {
    if (values.password === reactAppPublicApiUrlSecretPassword) {
      localStorage.setItem(savedConstants.IS_AUTH, 'auth');
      setError(false);
      window.location.reload();
    } else {
      setError(true);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      className={styles.wrapper}
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t('components.entryPassword.title'),
          },
        ]}
      >
        <Input.Password
          placeholder={t('components.entryPassword.password')}
          autoComplete="off"
        />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        {t('components.entryPassword.login')}
      </Button>
      {error && <div>{t('components.entryPassword.invalid')}</div>}
    </Form>
  );
};

export { EntryPassword };
