import up from 'assets/up.svg';
import hot from 'assets/hot.svg';
import weekly from 'assets/weekly.svg';
import deposit from 'assets/deposit.svg';

export const formatBackToHistory = (data) => data.reduce((historyData, item) => {
  const itemCopy = { ...item };
  const day = itemCopy.date.split('T')[0];
  const actualDay = historyData.find((d) => d.date === day);

  switch (itemCopy.name) {
    case 'Up':
      itemCopy.icon = up;
      break;
    case 'Weekly offer':
      itemCopy.icon = weekly;
      break;
    case 'Hot deal':
      itemCopy.icon = hot;
      break;
    case 'Deposit':
      itemCopy.icon = deposit;
      break;
    default:
      return itemCopy;
  }

  if (actualDay) {
    actualDay.offers.push(itemCopy);
    actualDay.offers.sort((a, b) => new Date(b.date) - new Date(a.date));
  } else {
    historyData.push({
      date: day,
      offers: [itemCopy],
    });
  }

  return historyData.sort((a, b) => new Date(b.date) - new Date(a.date));
}, []);

export const walletDataExample = [
  {
    name: 'Weekly offer',
    date: '2023-11-02T10:20:00.000Z',
    price: 40,
    type: 'minus',
    id: 2,
  },
  {
    name: 'Up',
    date: '2023-11-02T10:00:00.000Z',
    price: 100,
    type: 'minus',
    id: 1,
  },
  {
    name: 'Hot deal',
    date: '2023-11-02T20:00:00.000Z',
    price: 50,
    type: 'minus',
    id: 4,
  },
  {
    name: 'Deposit',
    date: '2023-10-02T23:20:00.000Z',
    price: 50,
    type: 'plus',
    id: 3,
  },
  {
    name: 'Up',
    date: '2023-11-03T10:00:00.000Z',
    price: 100,
    type: 'minus',
    id: 5,
  },
  {
    name: 'Weekly offer',
    date: '2023-11-01T10:20:00.000Z',
    price: 40,
    type: 'minus',
    id: 6,
  },
  {
    name: 'Hot deal',
    date: '2023-11-01T20:00:00.000Z',
    price: 50,
    type: 'minus',
    id: 7,
  },
  {
    name: 'Deposit',
    date: '2023-11-01T21:00:00.000Z',
    price: 50,
    type: 'plus',
    id: 8,
  },
  {
    name: 'Hot deal',
    date: '2023-10-01T20:00:00.000Z',
    price: 50,
    type: 'minus',
    id: 9,
  },
  {
    name: 'Deposit',
    date: '2023-10-01T21:00:00.000Z',
    price: 50,
    type: 'plus',
    id: 10,
  },
  {
    name: 'Hot deal',
    date: '2023-10-01T20:00:00.000Z',
    price: 50,
    type: 'minus',
    id: 11,
  },
  {
    name: 'Deposit',
    date: '2023-10-01T21:00:00.000Z',
    price: 50,
    type: 'plus',
    id: 12,
  },
];
