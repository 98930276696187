/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { usePublicVersion } from 'hooks/usePublicVersion';
import { useFlipper } from 'hooks/useFlipper';

import { useLocalContext } from 'LocalContext';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { DividerWithLogo } from 'components/DividerWithLogo';
import { CatalogList } from 'components/CatalogList';
import { Reviews } from 'components/Reviews';

import styles from './styled.module.css';

const VisitorProfile = () => {
  const {
    localState: {
      userInfo: { profile, favorites, reviews },
    },
  } = useLocalContext();

  const { t } = useTranslation();
  const { isPublicVersion } = usePublicVersion();
  const { isFlipperFeatureEnabled: isReviewsEnabled } = useFlipper('reviews');

  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>
        <h1>{profile.nickname}</h1>
        <p>{t('common.email')}</p>
        <p>{profile.email}</p>
      </div>
      <DividerWithLogo />
      <div className={styles.favourites}>
        <h1>{t('pages.profile.myProfile.6')}</h1>
        {favorites?.length ? (
          <CatalogList items={favorites} />
        ) : (
          <div className={styles.withoutFavourites}>
            <p>{t('pages.profile.myProfile.8')}</p>
            <Link
              className={styles.buttonLink}
              to="/catalog/?page=1&city=&gender=female&orientation=hetero"
            >
              <Button type="primary" className={styles.button}>
                <span>{t('pages.profile.myProfile.9')}</span>
              </Button>
            </Link>
            {/* <img src={isPublicVersion ? publicLogo : logo} alt="logo" /> */}
          </div>
        )}
      </div>
      {isReviewsEnabled && (
        <>
          <DividerWithLogo />
          <div className={styles.reviews}>
            <p className={styles.reviewsTitle}>
              ⭐
              {' '}
              {t('components.reviews.23')}
            </p>
            <Reviews reviews={reviews} />
          </div>
        </>
      )}
    </section>
  );
};

export default VisitorProfile;
