import React, { useMemo, useState } from 'react';
import { DatePicker, Input, Select } from 'antd';

import search from 'assets/search.svg';
import { offerSortDays } from 'utils/filters';
import { formatBackToHistory, walletDataExample } from 'utils/wallet';

import styles from './styled.module.css';
import './styles.css';
import { useTranslation } from 'react-i18next';

const History = () => {
  const { t } = useTranslation();
  const historyData = walletDataExample;
  const [searchInput, setSearchInput] = useState('');
  const [selectInput, setSelectInput] = useState(91);
  const [datePickerInput, setDatePickerInput] = useState(null);

  const today = new Date().toLocaleDateString().split('.').reverse()
    .join('-');

  const lastRangeDay = useMemo(
    () => new Date(today).setDate(new Date(today).getDate() - selectInput),
    [selectInput],
  );

  const rangeDays = useMemo(() => {
    if (datePickerInput) {
      return {
        start: new Date(datePickerInput[0]).setHours(0, 0, 0, 0),
        end: new Date(datePickerInput[1]).setHours(0, 0, 0, 0),
      };
    }
    return {};
  }, [datePickerInput]);

  const sortedHistoryData = useMemo(
    () => historyData.filter((item) => {
      const { name, date } = item;
      const dateDay = new Date(date).setHours(0, 0, 0, 0);
      return (
        (!searchInput || name.toLowerCase().includes(searchInput))
          && (!selectInput || dateDay > lastRangeDay)
          && (!datePickerInput
            || (dateDay >= rangeDays.start && dateDay <= rangeDays.end))
      );
    }),
    [historyData, searchInput, selectInput, JSON.stringify(datePickerInput)],
  );

  const formatHistoryData = useMemo(
    () => formatBackToHistory(sortedHistoryData),
    [sortedHistoryData],
  );

  const offerDay = (item) => {
    if (item === today) return t('common.today');
    if (
      item.slice(0, -2) === today.slice(0, -2)
      && +item.slice(-2) === +today.slice('-2') - 1
    ) {
      return t('common.yesterday');
    }
    return item;
  };

  const disabledDate = (current) => current > new Date()
    || current < new Date(today).setDate(new Date(today).getDate() - 91);

  return (
    <div className={styles.wrapper}>
      <div className={styles.balanceWrapper}>
        <div className={styles.balanceTitle}>
          <p className={styles.title}>{t('pages.profile.wallet.1')}</p>
          <p>
            {t('pages.profile.wallet.2')}
            {' '}
            <b>{t('pages.profile.wallet.3')}</b>
            {' '}
            {t('pages.profile.wallet.4')}
            <br />
            {t('pages.profile.wallet.5')}
          </p>
        </div>
        <div className={styles.balanceBlock}>
          <p>{t('pages.profile.wallet.6')}</p>
          <p className={styles.balance}>0 GTL</p>
          <p>1EUR = 10 GTL</p>
        </div>
      </div>
      <div className={styles.history}>
        <p className={styles.title}>{t('pages.profile.wallet.history.1')}</p>
        <div className={styles.searchBlock}>
          <Input
            placeholder={t('pages.profile.wallet.history.2')}
            className={styles.search}
            maxLength={30}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
            prefix={<img alt="search" src={search} />}
            allowClear
          />
          <div className={styles.selectDatePicker}>
            <Select
              className={styles.select}
              options={offerSortDays}
              placeholder={t('pages.profile.wallet.history.3')}
              value={selectInput}
              onChange={(e) => {
                if (datePickerInput) setDatePickerInput([]);
                setSelectInput(e);
              }}
            />
            <DatePicker.RangePicker
              className={styles.timePicker}
              rootClassName="history"
              value={datePickerInput}
              onChange={(e) => {
                if (selectInput) setSelectInput(null);
                setDatePickerInput(e);
              }}
              disabledDate={disabledDate}
            />
          </div>
        </div>
        <div className={styles.historyBlock}>
          {formatHistoryData.map((item) => (
            <div key={item.date}>
              <p className={styles.offerDay}>{offerDay(item.date)}</p>
              <div className={styles.offerDayBlock}>
                {item.offers.map((offer) => (
                  <div
                    key={offer.id}
                    className={`${styles.offerDayLine} ${
                      offer.type === 'plus' ? styles.plusOffer : ''
                    }`}
                  >
                    <div className={styles.offerName}>
                      <img src={offer.icon} alt={offer.name} />
                      <p>{offer.name}</p>
                    </div>
                    <p>
                      {offer.type === 'minus' ? '-' : '+'}
                      {offer.price}
                      {' '}
                      GTL
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { History };
