import React from 'react';
import { Button as AntdButton } from 'antd';
import PropTypes from 'prop-types';

import styles from './styled.module.css';

const Button = ({
  primary = false,
  secondary = false,
  disabled = false,
  loading = false,
  onClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
  className = '',
  submit = false,
  children,
  fullWidth = false,
  type = '',
  form,
  id,
}) => (
  <AntdButton
    type={type || 'default'}
    rootClassName={`
    ${className}
    ${type ? styles.buttonLink : styles.buttonDefault}
    ${fullWidth ? styles.fullWidth : ''}
    ${primary ? styles.primary : ''}
    ${secondary ? styles.secondary : ''}
    `}
    disabled={disabled}
    loading={loading}
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
    id={id}
    form={form}
    htmlType={submit ? 'submit' : 'button'}
  >
    {children}
  </AntdButton>
);

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  submit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  form: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
};

export { Button };
