import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLocalContext } from 'LocalContext';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { answersStatus, reviewsStatus } from 'constants/reviewsStatus';

import { Button } from 'components/Button';
import { Basket } from 'components/ActiveIcon/Basket';
import { ReviewModal } from '../ReviewModal';
import { ReviewDeleteModal } from '../ReviewDeleteModal';

import styles from './styled.module.css';

const ReviewItem = ({ item, setReviewsData, scroll }) => {
  const {
    review: {
      id_name: review_id_name = '',
      text: review_text = '',
      status: review_status = '',
      name,
      link,
      card_id,
    } = {},
    answer: { text: answer_text = '', status: answer_status = '' } = {},
  } = item;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { userInfo },
  } = useLocalContext();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSendAnswer, setIsSendAnswer] = useState(false);

  const isHaveVisitorReview = userInfo?.user_type === userVisitor
    && review_id_name === userInfo?.profile?.nickname;

  let answerTitle = '';
  if (
    isSendAnswer
    || (card_id === userInfo?.profile?.id
      && userInfo?.user_type === userProvider
      && answer_status === answersStatus.moderation)
  ) {
    answerTitle = t('components.reviews.15');
  } else if (
    (answer_text && answer_status === answersStatus.published)
    || (userInfo?.user_type === userProvider
      && card_id === userInfo?.profile?.id
      && answer_status === answersStatus.declined)
  ) {
    answerTitle = t('components.reviews.16');
  } else if (
    !answer_text
    || (answer_text && answer_status !== answersStatus.published)
  ) {
    answerTitle = t('components.reviews.17');
  }

  return (
    <>
      <section
        className={`
        ${styles.review}
        ${isHaveVisitorReview ? styles.visitorReview : ''}
        ${review_status === reviewsStatus.declined ? styles.declinedReview : ''}
        ${
          answer_status === answersStatus.declined
          && card_id === userInfo?.profile?.id
          && userInfo?.user_type === userProvider
            ? styles.declinedReview
            : ''
        }
        `}
        onClick={() => {
          if (!scroll && review_status !== reviewsStatus.moderation) {
            setOpenModal(true);
          }
        }}
      >
        <div className={styles.reviewBlock}>
          <h3>
            {isHaveVisitorReview
              ? `${t('components.reviews.13')}${
                userInfo.user_type === userVisitor && name
                  ? ` ${t('components.reviews.14')}`
                  : ''
              }`
              : review_id_name}
            {isHaveVisitorReview && (
              <Button
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/${link}`);
                }}
              >
                {name}
              </Button>
            )}
          </h3>
          {review_status !== reviewsStatus.moderation ? (
            <p>
              {review_text?.length > 80
                ? `${review_text?.slice(0, 80)}...`
                : review_text}
            </p>
          ) : (
            <p>{t('components.reviews.18')}</p>
          )}
        </div>
        {review_status === reviewsStatus.published && (
          <div className={`${styles.reviewBlock} ${styles.answerBlock}`}>
            <h3>{answerTitle}</h3>
            {answer_status === answersStatus.published && (
              <p>
                {answer_text?.length > 80
                  ? `${answer_text?.slice(0, 80)}...`
                  : answer_text}
              </p>
            )}
            {answer_status === answersStatus.declined
              && card_id === userInfo?.profile?.id
              && userInfo?.user_type === userProvider && (
                <p className={styles.declined}>{t('components.reviews.27')}</p>
            )}
          </div>
        )}
        {review_status === reviewsStatus.declined && (
          <p className={styles.declined}>{t('components.reviews.25')}</p>
        )}
        {review_status !== reviewsStatus.moderation && (
          <Button type="link" className={styles.seeAll}>
            {t('common.seeAll')}
          </Button>
        )}
        {userInfo?.user_type === userProvider
          && answer_status === answersStatus.not_answered
          && card_id === userInfo?.profile?.id && (
            <Button type="link" className={styles.replay}>
              Replay
            </Button>
        )}
        {isHaveVisitorReview && review_status === reviewsStatus.published && (
          <Button
            type="link"
            className={styles.basket}
            onClick={(e) => {
              if (!scroll) {
                e.stopPropagation();
                setOpenDeleteModal(true);
              }
            }}
          >
            <Basket className={styles.basketImage} />
          </Button>
        )}
      </section>
      <ReviewModal
        open={openModal}
        setOpen={setOpenModal}
        item={item}
        isSendAnswer={isSendAnswer}
        setIsSendAnswer={setIsSendAnswer}
        setOpenDeleteModal={setOpenDeleteModal}
      />
      <ReviewDeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        reviewId={item.id}
        setReviewsData={setReviewsData}
      />
    </>
  );
};

ReviewItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    review: PropTypes.shape({
      id_name: PropTypes.string,
      text: PropTypes.string,
      status: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
      card_id: PropTypes.number,
    }),
    answer: PropTypes.shape({
      id_name: PropTypes.string,
      text: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
  setReviewsData: PropTypes.func,
  scroll: PropTypes.bool,
};

export { ReviewItem };
