import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { destroyReviewVisitor } from 'api/requests/reviews';

import { useLocalContext } from 'LocalContext';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const ReviewDeleteModal = ({
  open, setOpen, reviewId, setReviewsData,
}) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const deleteReview = async () => {
    setLoading(true);
    const query = {
      review_id: reviewId,
    };

    try {
      await destroyReviewVisitor(query);
      setOpen(false);
      setError(false);
      setReviewsData((prev) => [
        ...prev.filter((item) => item.id !== reviewId),
      ]);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          reviews: userInfo?.reviews?.filter((item) => item.id !== reviewId),
        },
      });
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      footer={(
        <div className={styles.buttonBlock}>
          <Button secondary loading={loading} onClick={deleteReview}>
            {!loading && t('components.reviews.11')}
          </Button>
          <Button primary onClick={() => setOpen(false)}>
            {t('components.reviews.12')}
          </Button>
          {error && (
            <p className={styles.error}>{t('errors.somethingWrong')}</p>
          )}
        </div>
      )}
    >
      <div className={styles.wrapper}>
        <h1>{t('components.reviews.8')}</h1>
        <p>
          {t('components.reviews.9')}
          {' '}
          <b>{t('components.reviews.10')}</b>
          ?
        </p>
      </div>
    </ModalWrapper>
  );
};

ReviewDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  reviewId: PropTypes.number.isRequired,
  setReviewsData: PropTypes.func.isRequired,
};

export { ReviewDeleteModal };
