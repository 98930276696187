import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useLocalContext } from 'LocalContext';

import { paths } from 'constants';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
// import { TopArrow } from 'components/TopArrow';

import styles from './styled.module.css';

const GlobalWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const {
    localState: { isBlocking },
  } = useLocalContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.globalWrapper}>
      {pathname !== paths.paymentPage && !isBlocking && <Header />}
      <main
        id="main"
        className={`
        ${styles.main}
        ${
          pathname === paths.paymentPage || isBlocking
            ? styles.withoutHeaderFooter
            : ''
        }
        `}
      >
        <div className={styles.wrapper}>{children}</div>
        {/* <TopArrow /> */}
      </main>
      {pathname !== paths.paymentPage && !isBlocking && <Footer />}
    </div>
  );
};

GlobalWrapper.propTypes = {
  children: PropTypes.node,
};

export { GlobalWrapper };
