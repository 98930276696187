export const reviewsStatus = {
  published: 'published',
  moderation: 'moderation',
  declined: 'declined',
};

export const answersStatus = {
  published: 'published',
  moderation: 'moderation',
  declined: 'declined',
  not_answered: 'not_answered',
};
