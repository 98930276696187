import { Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setHideShowProfile, setShowProfileModals } from 'api/requests';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { verificationStatus } from 'constants/verificationStatus';
import { profileStatus } from 'constants/profileStatus';
import { status } from 'constants/status';
import {
  catalogProfileRatesColums,
  catalogProfileRatesRows,
} from 'utils/catalogProfile';

import { useFlipper } from 'hooks/useFlipper';

import {
  Availability,
  Contacts,
  NameInfo,
  ProfileDescriptions,
  Services,
  TabsMobile,
  UserInfo,
} from 'components/AboutUser';
import { Avatar } from 'components/Avatar';
import { EditIcon } from 'components/ActiveIcon/EditIcon';
import { PauseIcon } from 'components/ActiveIcon/PauseIcon';
import { ViewIcon } from 'components/ActiveIcon/ViewIcon';
import { DividerWithLogo } from 'components/DividerWithLogo';
import { PhotoCarousel } from 'components/PhotoCarousel';
import { NotificationModal } from 'components/NotificationModal';
import { VerifiedIcon } from 'components/ActiveIcon/VerifiedIcon';
import { PlayIcon } from 'components/ActiveIcon/PlayIcon';
import { Reviews } from 'components/Reviews';

import { ProfileNotifiaction } from 'pages/Profile/components/ProfileNotification';
import { LiftingBlock } from 'pages/Profile/components/LiftingBlock';
import { LiftingBlockButton } from 'pages/Profile/components/LiftingBlock/components/LiftingBlockButton';

import styles from './styled.module.css';

const ProviderProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { userInfo, isShowNotification },
    localDispatch,
  } = useLocalContext();
  const { isFlipperFeatureEnabled: isReviewsEnabled } = useFlipper('reviews');

  const album = userInfo?.albums?.[0]?.images;

  const isProfilePublished = userInfo?.status === profileStatus.published
    && !userInfo?.showed_modal_moderation;
  const isProfileVerified = userInfo?.status === profileStatus.published
    && userInfo?.verification_status === verificationStatus.verified
    && !userInfo?.showed_modal_verified;

  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 860,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 860);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024 && window.innerWidth > 860) {
        setIsTablet(true);
        setIsMobile(false);
      } else if (window.innerWidth <= 860) {
        setIsMobile(true);
        setIsTablet(false);
      } else {
        setIsMobile(false);
        setIsTablet(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isProfilePublished || isProfileVerified) {
      localDispatch({
        type: 'SET_IS_SHOW_NOTIFICATION',
        payload: { isShow: true, status: status.success },
      });
    }
  }, [isProfilePublished, isProfileVerified]);

  const hasAvailableDay = useMemo(
    () => !!userInfo
      && !!userInfo.availability_calendar
      && userInfo.availability_calendar.some((day) => day.available === true),
    [userInfo && userInfo.availability_calendar],
  );

  const onCloseModal = async () => {
    let query = {};
    if (isProfilePublished) {
      query = {
        showed_modal_moderation: !userInfo?.showed_modal_moderation,
      };
    } else if (isProfileVerified) {
      query = {
        showed_modal_verified: !userInfo?.showed_modal_verified,
      };
    }

    try {
      const {
        data: {
          data: {
            profile: { showed_modal_moderation, showed_modal_verified },
          },
        },
      } = await setShowProfileModals(query);

      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          showed_modal_moderation,
          showed_modal_verified,
        },
      });
    } catch {
      throw new Error('showed_profile_modals has problem');
    }
  };

  const hideShowProfile = async () => {
    try {
      const {
        data: { status: dataStatus },
      } = await setHideShowProfile();
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          status: dataStatus,
        },
      });
    } catch {
      throw new Error('toggle_hide_profile');
    }
  };

  const modalText = () => {
    let modalTitle;
    let modalDescription;
    if (isProfilePublished) {
      modalTitle = t('pages.profile.myProfile.4');
      modalDescription = <p>{t('pages.profile.myProfile.5')}</p>;
    } else if (isProfileVerified) {
      modalTitle = t('pages.profile.components.profileNotification.11');
      modalDescription = (
        <p>
          {t('pages.profile.components.profileNotification.12')}
          <VerifiedIcon />
          <br />
          {t('pages.profile.components.profileNotification.13')}
          {' '}
          <b>{t('pages.profile.components.profileNotification.14')}</b>
          <br />
          {t('pages.profile.components.profileNotification.16')}
          {' '}
          <b>
            {t('pages.profile.components.profileNotification.17')}
            {' '}
            &
            {' '}
            {t('pages.profile.components.profileNotification.18')}
          </b>
          .
          <br />
          {t('pages.profile.components.profileNotification.35')}
          {' '}
          <b>{t('common.social_media')}</b>
        </p>
      );
    }

    return { modalTitle, modalDescription };
  };

  const { modalTitle, modalDescription } = modalText();

  return (
    <>
      {userInfo.status && <ProfileNotifiaction />}
      <div className={styles.wrapper}>
        <div className={styles.leftBlock}>
          <div className={styles.avatarAvailabilityBlock}>
            <Avatar images={userInfo?.images} />
            {isMobile && <NameInfo profile={userInfo.profile} />}
            {userInfo.status === profileStatus.archived && (
              <div className={styles.hideShowInfo}>
                <h3>{t('pages.profile.myProfile.10')}</h3>
                <p>{t('pages.profile.myProfile.11')}</p>
              </div>
            )}
            <div className={styles.buttonBlock}>
              <Link
                to={
                  userInfo.status !== profileStatus.moderation
                  && userInfo.verification_status
                    !== verificationStatus.verification
                  && userInfo.can_edit_profile
                  && paths.profileCreation
                }
                className={styles.buttonLink}
              >
                <div
                  className={`${styles.button} ${
                    userInfo.status === profileStatus.moderation
                    || !userInfo.can_edit_profile
                    || userInfo.verification_status
                      === verificationStatus.verification
                      ? styles.buttonDisabled
                      : ''
                  }`}
                >
                  <EditIcon className={styles.activeIconStroke} />
                  <p>{t('pages.profile.myProfile.1')}</p>
                </div>
              </Link>
              <Link
                to={
                  userInfo.status === profileStatus.published
                  && paths.catalogProfile(userInfo.profile.id_name)
                }
                state={userInfo}
                className={styles.buttonLink}
              >
                <div
                  className={`${styles.button} ${
                    userInfo.status !== profileStatus.published
                      ? styles.buttonDisabled
                      : ''
                  }`}
                >
                  <ViewIcon className={styles.activeIconFill} />
                  <p>{t('pages.profile.myProfile.2')}</p>
                </div>
              </Link>
              <div
                className={`${styles.button} ${
                  (userInfo.status !== profileStatus.published
                    && userInfo.status !== profileStatus.archived)
                  || !userInfo.can_edit_profile
                    ? styles.buttonDisabled
                    : ''
                } ${
                  userInfo.status === profileStatus.archived ? styles.hide : ''
                }`}
                onClick={() => {
                  if (
                    (userInfo.status === profileStatus.published
                      || userInfo.status === profileStatus.archived)
                    && userInfo.can_edit_profile
                  ) {
                    hideShowProfile();
                  }
                }}
              >
                {userInfo.status === profileStatus.archived ? (
                  <PlayIcon />
                ) : (
                  <PauseIcon className={styles.activeIconFill} />
                )}
                <p>
                  {userInfo.status === profileStatus.archived
                    ? t('common.show')
                    : t('common.hide')}
                </p>
              </div>
            </div>
            {!isTablet && !isMobile && <DividerWithLogo />}
            {hasAvailableDay && !isTablet && (
              <>
                {isMobile && <DividerWithLogo />}
                <Availability availability={userInfo.availability_calendar} />
                {!isTablet && !isMobile && <DividerWithLogo />}
              </>
            )}
          </div>
          {!isMobile && (
            <div className={styles.infoContactsBlock}>
              {isTablet && <NameInfo profile={userInfo.profile} />}
              <UserInfo profile={userInfo.profile} />
              {!isTablet && (
                <>
                  <DividerWithLogo />
                  <Contacts
                    profile={userInfo.profile}
                    rates={userInfo.rates}
                    messengers={userInfo.messengers}
                    social_networks={userInfo.social_networks}
                    myProfile
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.rightBlock}>
          {hasAvailableDay && isTablet && (
            <>
              {isTablet && <DividerWithLogo />}
              <Availability availability={userInfo.availability_calendar} />
            </>
          )}
          {!isTablet && !isMobile && (
            <>
              <NameInfo profile={userInfo.profile} />
              <LiftingBlock />
            </>
          )}
          <DividerWithLogo />
          {isMobile
            && (userInfo.profile || userInfo.services || userInfo.rates) && (
              <TabsMobile
                profile={userInfo.profile}
                services={userInfo.services}
                rates={userInfo.rates}
              />
          )}
          {!!userInfo.profile_descriptions && (
            <>
              <ProfileDescriptions
                descriptions={userInfo.profile_descriptions}
                profile={userInfo.profile}
              />
              <DividerWithLogo />
            </>
          )}
          {!isMobile && !!userInfo.services && (
            <>
              <Services services={userInfo.services} />
              <DividerWithLogo />
            </>
          )}
          {!isMobile && !!userInfo.rates && (
            <div className={styles.rates}>
              <p>{t('common.rates')}</p>
              <Table
                className="contacts"
                dataSource={catalogProfileRatesRows(userInfo.rates)}
                columns={catalogProfileRatesColums}
                pagination={false}
              />
              <DividerWithLogo />
            </div>
          )}
        </div>
        {(isTablet || isMobile) && (
          <Contacts
            profile={userInfo.profile}
            rates={userInfo.rates}
            messengers={userInfo.messengers}
            social_networks={userInfo.social_networks}
            myProfile
          />
        )}
      </div>
      {!!album?.length && <PhotoCarousel images={album} />}
      {isReviewsEnabled && (
        <>
          <DividerWithLogo />
          <Reviews reviews={userInfo?.reviews} />
        </>
      )}
      {(isTablet || isMobile) && <LiftingBlockButton />}
      {isShowNotification.isShow && (
        <NotificationModal
          onClose={onCloseModal}
          logo
          messageSuccess={modalTitle}
          description={modalDescription}
          buttonName={
            isProfileVerified
            && !isProfilePublished
            && t('pages.profile.myProfile.12')
          }
          onButtonClick={() => navigate(paths.profileCreation, { state: 1 })}
        />
      )}
    </>
  );
};

export default ProviderProfile;
