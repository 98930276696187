import { useEffect } from 'react';

const useBlockScroll = (open) => {
  useEffect(() => {
    const main = document.getElementById('root');
    if (open) {
      const { scrollY } = window;
      main.style.position = 'fixed';
      main.style.top = `-${scrollY}px`;
    }

    return () => {
      const scrollY = main.style.top
        ? parseInt(main.style.top.replace('px', ''), 10)
        : 0;
      main.style.position = '';
      main.style.top = 0;
      window.scrollBy(0, -scrollY);
    };
  }, [open]);
};

export { useBlockScroll };
