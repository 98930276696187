import { useEffect, useMemo, useState } from 'react';
import {
  Button, Form, Radio, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { usePrivateNavigate } from 'hooks/usePrivateNavigate';

import {
  createPayment,
  getCurrencies,
  getProposals,
} from 'api/requests/wallet';
import { useLocalContext } from 'LocalContext';
import { virtualCurrencies } from 'utils/filters';
import { paths } from 'constants';
import coinBg from 'assets/coinBg.svg';

import { Spinner } from 'components/Spinner';

import styles from './styled.module.css';
import './styles.css';

const Shop = () => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
  } = useLocalContext();
  const privateNavigate = usePrivateNavigate();
  const [form] = Form.useForm();

  const [allCurrencies, setAllCurrencies] = useState([]);
  const [allProposals, setAllProposals] = useState([]);

  const [currenciesLoading, setCurrenciesLoading] = useState(false);
  const [proposalsLoading, setProposalsLoading] = useState(false);
  const [proposalLoading, setProposalLoading] = useState(false);

  const [currenciesError, setCurrenciesError] = useState(false);
  const [proposalsError, setProposalsError] = useState(false);

  const actualCurrency = useMemo(
    () => (dependentValue) => virtualCurrencies.find(
      (item) => item.label.toLowerCase() === dependentValue,
    ).value || '',
    [virtualCurrencies],
  );

  const formatAllCurrencies = useMemo(
    () => (cur) => virtualCurrencies.filter((item) => cur.includes(item.value)),
    [virtualCurrencies, allCurrencies],
  );

  const createActualPayment = async (values) => {
    const query = values.offer;

    setProposalLoading(true);
    try {
      const res = await createPayment(query);
      setProposalsError(false);
      privateNavigate({ paths: paths.paymentPage, state: res.data });
    } catch (e) {
      setProposalsError(true);
    }
    setProposalLoading(false);
  };

  const getAllProposals = async (currency) => {
    const query = {
      selected_currency: actualCurrency(currency),
    };

    setProposalsLoading(true);
    try {
      const res = await getProposals(query);
      setAllProposals(res.data);
      setProposalsError(false);
    } catch (e) {
      setProposalsError(true);
    }
    setProposalsLoading(false);
  };

  const getAllCurrencies = async () => {
    setCurrenciesLoading(true);
    try {
      const {
        data: { currencies },
      } = await getCurrencies();
      if (currencies.length > 0) {
        setCurrenciesError(false);
        const formatCurrencies = formatAllCurrencies(currencies);
        setAllCurrencies(formatCurrencies);
        const query = {
          selected_currency: (
            formatCurrencies.find((item) => item.value === 'eth')
            || formatCurrencies[0]
          ).value,
        };

        try {
          const { data } = await getProposals(query);
          setAllProposals(data);
          setProposalsError(false);
        } catch (e) {
          setProposalsError(true);
        }
      } else {
        setCurrenciesError(true);
      }
    } catch (e) {
      setCurrenciesError(true);
    }
    setCurrenciesLoading(false);
  };

  useEffect(() => {
    getAllCurrencies();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.balanceWrapper}>
        <div className={styles.balanceTitle}>
          <p className={styles.title}>{t('pages.profile.wallet.1')}</p>
          <p>
            {t('pages.profile.wallet.2')}
            {' '}
            <b>{t('pages.profile.wallet.3')}</b>
            {' '}
            {t('pages.profile.wallet.4')}
            <br />
            {t('pages.profile.wallet.5')}
          </p>
        </div>
        <div className={styles.balanceBlock}>
          <p>{t('pages.profile.wallet.6')}</p>
          <p className={styles.balance}>
            {+userInfo.balance}
            {' '}
            GTL
          </p>
          <p>1EUR = 10 GTL</p>
        </div>
      </div>
      {currenciesLoading ? (
        <Spinner />
      ) : (
        <div className={styles.depositBlock}>
          {currenciesError ? (
            <p className={styles.errorCurrencies}>
              {t('pages.profile.wallet.shop.1')}
              <br />
              {t('pages.profile.wallet.shop.2')}
            </p>
          ) : (
            <>
              <Form
                onFinish={createActualPayment}
                autoComplete="off"
                form={form}
                className={styles.form}
              >
                <p>{t('pages.profile.wallet.shop.3')}</p>
                <Form.Item
                  name="currency"
                  className={styles.select}
                  initialValue={(
                    allCurrencies?.find((item) => item.value === 'eth')
                    || allCurrencies?.[0]
                  )?.label.toLowerCase()}
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectCurrency'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t('commmon.search')}
                    rootClassName="shop"
                    onChange={(e) => getAllProposals(e)}
                  >
                    {allCurrencies.map((item) => (
                      <Select.Option
                        key={item.value}
                        value={item.label.toLowerCase()}
                      >
                        <div className={styles.currencyItem}>
                          <img src={item.image} alt={item.value} />
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {proposalsLoading ? (
                  <Spinner />
                ) : (
                  <Form.Item dependencies={['currency']}>
                    {() => {
                      const dependentValue = form.getFieldValue('currency');
                      if (dependentValue) {
                        return !proposalsError
                          && allProposals
                          && allProposals.length ? (
                            <div className={styles.offerBlock}>
                              <p>{t('pages.profile.wallet.shop.4')}</p>
                              <Form.Item
                                name="offer"
                                className={styles.select}
                                rules={[
                                  {
                                    required: true,
                                    message: t('common.selectOffer'),
                                  },
                                ]}
                              >
                                <Radio.Group
                                  buttonStyle="solid"
                                  className={styles.radioGroup}
                                  rootClassName="shop"
                                >
                                  {allProposals.map((option) => (
                                    <Radio.Button
                                      key={option.token_amount}
                                      value={option}
                                      className={styles.radioItem}
                                    >
                                      <div className={styles.radioBlock}>
                                        <span>
                                          {option.token_amount}
                                          {' '}
                                          GTL =
                                          {' '}
                                          {parseFloat(+option.price_amount)}
                                          {' '}
                                          {option.price_currency.toUpperCase()}
                                        </span>
                                        {option.discount_rate && (
                                        <div className={styles.sale}>
                                          {+option.discount_rate * 100}
                                          %
                                          {' '}
                                          {t('pages.profile.wallet.7')}
                                        </div>
                                        )}
                                      </div>
                                    </Radio.Button>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                dependencies={['offer']}
                                className={styles.buttonBlock}
                              >
                                {() => {
                                  const dependentValueOffer = form.getFieldValue('offer');
                                  return (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className={`${styles.button} ${styles.submit}`}
                                      loading={proposalLoading}
                                      disabled={!dependentValueOffer}
                                    >
                                      {t('pages.profile.wallet.shop.5')}
                                    </Button>
                                  );
                                }}
                              </Form.Item>
                            </div>
                          ) : (
                            <p className={styles.error}>
                              {t('pages.profile.wallet.shop.6')}
                              <br />
                              {t('pages.profile.wallet.shop.7')}
                            </p>
                          );
                      }

                      return <p>{t('pages.profile.wallet.shop.8')}</p>;
                    }}
                  </Form.Item>
                )}
              </Form>
              <div className={styles.crypto}>
                <img src={coinBg} alt={coinBg} />
                <div className={styles.cryptoInfo}>
                  <div>
                    <p>{t('pages.profile.wallet.shop.9')}</p>
                    <p>{t('pages.profile.wallet.shop.10')}</p>
                    <p>
                      {t('pages.profile.wallet.shop.11')}
                      <br />
                      {t('pages.profile.wallet.shop.12')}
                    </p>
                  </div>
                  <Button
                    type="primary"
                    className={`${styles.button} ${styles.passive}`}
                  >
                    {t('pages.profile.wallet.shop.13')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { Shop };
