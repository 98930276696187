import React from 'react';
import PropTypes from 'prop-types';

const Basket = ({ className, onClick, id }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="#633976"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
    id={id}
  >
    <path
      d="M2.48827 15C2.06214 15 1.69943 14.8503 1.40013 14.551C1.10082 14.2517 0.951173 13.8893 0.951173 13.4639V1.68358H0V0.732403H3.80469V0H9.51173V0.732403H13.3164V1.68358H12.3652V13.4639C12.3652 13.9014 12.2188 14.267 11.9258 14.5606C11.6328 14.8542 11.267 15.0006 10.8282 15H2.48827ZM11.4141 1.68358H1.90235V13.4639C1.90235 13.6344 1.9572 13.7746 2.0669 13.8843C2.1766 13.994 2.31706 14.0488 2.48827 14.0488H10.8291C10.975 14.0488 11.1091 13.988 11.2315 13.8662C11.3538 13.7444 11.4147 13.61 11.4141 13.4629V1.68358ZM4.57324 12.1465H5.52441V3.58592H4.57324V12.1465ZM7.79201 12.1465H8.74318V3.58592H7.79201V12.1465Z"
    />
  </svg>
);

Basket.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export { Basket };
