import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { ProfileLayout } from '../components/ProfileLayout';
import { History } from './components/History';
import { Shop } from './components/Shop';

import styles from './styled.module.css';
import './styles.css';

const Wallet = () => {
  const { t } = useTranslation();
  const items = [
    {
      key: '1',
      label: <p>{t('common.wallet')}</p>,
      children: <Shop />,
    },
    {
      key: '2',
      label: <p>{t('common.transactionsHistory')}</p>,
      children: <History />,
    },
  ];
  return (
    <ProfileLayout>
      <div className={styles.wrapper}>
        <Tabs
          defaultActiveKey="1"
          items={items}
          rootClassName="wallet"
          tabBarExtraContent={<p className={styles.cryptoLink}>{t('common.learnCrypto')}</p>}
        />
      </div>
    </ProfileLayout>
  );
};

export { Wallet };
