import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';

import { answerForReviewProvider } from 'api/requests/reviews';

import { useLocalContext } from 'LocalContext';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { answersStatus, reviewsStatus } from 'constants/reviewsStatus';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const ReviewModal = ({
  open,
  setOpen,
  item,
  isSendAnswer,
  setIsSendAnswer,
  setOpenDeleteModal,
}) => {
  const {
    review: {
      id_name: review_id_name = '',
      text: review_text = '',
      status: review_status = '',
      name,
      link,
      card_id,
    } = {},
    answer: { text: answer_text = '', status: answer_status = '' } = {},
  } = item;
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendReview = async () => {
    setLoading(true);
    const { answer } = form.getFieldsValue();
    const query = {
      review_id: item.id,
      answer,
    };

    try {
      await answerForReviewProvider(query);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          reviews: userInfo?.reviews?.map((reviewItem) => (reviewItem.id === item.id
            ? {
              ...item,
              answer: { status: answersStatus.moderation, text: answer },
            }
            : reviewItem)),
        },
      });
      setError(false);
      setIsSendAnswer(true);
      setOpen(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const isShowFooter = userInfo?.user_type === userVisitor
    && review_id_name === userInfo?.profile?.nickname
    && review_status === reviewsStatus.published;

  const isShowAnswerForm = userInfo?.user_type === userProvider
    && card_id === userInfo?.profile?.id
    && !answer_text
    && !isSendAnswer;

  let answerTitle = '';
  if (
    isSendAnswer
      || (card_id === userInfo?.profile?.id
        && userInfo?.user_type === userProvider
        && answer_status === answersStatus.moderation)
  ) {
    answerTitle = t('components.reviews.15');
  } else if (
    (answer_text && answer_status === answersStatus.published)
      || (userInfo?.user_type === userProvider
        && card_id === userInfo?.profile?.id
        && answer_status === answersStatus.declined)
  ) {
    answerTitle = t('components.reviews.16');
  } else if (
    !answer_text
      || (answer_text && answer_status !== answersStatus.published)
  ) {
    answerTitle = t('components.reviews.17');
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      footer={
        isShowFooter && (
          <div className={styles.buttonBlock}>
            <Button
              primary
              className={styles.delete}
              onClick={() => {
                setOpen(false);
                setOpenDeleteModal(true);
              }}
            >
              {t('components.reviews.11')}
            </Button>
            {error && (
              <p className={styles.error}>{t('errors.somethingWrong')}</p>
            )}
          </div>
        )
      }
    >
      <section className={styles.wrapper}>
        <div className={styles.reviewBlock}>
          <h3>
            {userInfo?.user_type === userVisitor
            && review_id_name === userInfo?.profile?.nickname
              ? `${t('components.reviews.13')}${
                userInfo.user_type === userVisitor && name
                  ? ` ${t('components.reviews.14')}`
                  : ''
              }`
              : review_id_name}
            {userInfo?.user_type === userVisitor
              && review_id_name === userInfo?.profile?.nickname && (
                <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/${link}`);
                  }}
                >
                  {name}
                </Button>
            )}
          </h3>
          <p>{review_text}</p>
        </div>
        {review_status === reviewsStatus.published && (
          <div className={`${styles.reviewBlock} ${styles.answerBlock}`}>
            <h3>{answerTitle}</h3>
            {answer_status === answersStatus.published && <p>{answer_text}</p>}
            {answer_status === answersStatus.declined
              && card_id === userInfo?.profile?.id
              && userInfo?.user_type === userProvider && (
                <>
                  <h3 className={styles.declined}>
                    {t('components.reviews.27')}
                  </h3>
                  <p>{t('components.reviews.26')}</p>
                </>
            )}
          </div>
        )}
        {review_status === reviewsStatus.declined && (
          <>
            <h3 className={styles.declined}>{t('components.reviews.25')}</h3>
            <p>{t('components.reviews.26')}</p>
          </>
        )}
        {isShowAnswerForm && (
          <div className={styles.answerForm}>
            <h3>{t('components.reviews.19')}</h3>
            <Form autoComplete="off" form={form} className={styles.form}>
              <Form.Item
                name="answer"
                className="report"
                rules={[
                  {
                    required: true,
                    message: t('errors.enterMessage'),
                  },
                ]}
              >
                <TextArea
                  placeholder={t('components.reviews.20')}
                  maxLength={400}
                  showCount
                  allowClear
                />
              </Form.Item>
              <Form.Item dependencies={['answer']}>
                {() => {
                  const dependentValue = form.getFieldValue('answer');
                  return (
                    <Button
                      onClick={async () => {
                        await sendReview();
                      }}
                      secondary
                      loading={loading}
                      disabled={!dependentValue}
                    >
                      {t('components.reviews.21')}
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        )}
      </section>
    </ModalWrapper>
  );
};

ReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    review: PropTypes.shape({
      id_name: PropTypes.string,
      text: PropTypes.string,
      status: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
      card_id: PropTypes.number,
    }),
    answer: PropTypes.shape({
      id_name: PropTypes.string,
      text: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
  isSendAnswer: PropTypes.bool.isRequired,
  setIsSendAnswer: PropTypes.func.isRequired,
  setOpenDeleteModal: PropTypes.func.isRequired,
};

export { ReviewModal };
